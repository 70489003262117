import { Component, OnInit } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { ReleaseNotesUpdateService } from '../services/release-notes-update/release-notes-update.service';

@Component({
  selector: 'xpo-footer',
  templateUrl: './xpo-footer.component.html',
  styleUrls: ['./xpo-footer.component.scss'],
})
export class XpoFooterComponent implements OnInit {
  trainingResourcesLink: string;
  releaseNotesLink: string;
  buildVersion: string;
  year: string;
  constructor(
    private configManagerService: ConfigManagerService,
    public releaseNotesUpdateService: ReleaseNotesUpdateService
  ) {}

  ngOnInit() {
    this.buildVersion = this.configManagerService.getSetting('buildVersion');
    this.year = new Date().getFullYear().toString();
    this.trainingResourcesLink = this.configManagerService.getSetting(ConfigManagerProperties.trainingResourcesLink);
    this.releaseNotesLink = this.configManagerService.getSetting(ConfigManagerProperties.releaseNotesLink);
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesUpdateService.dismissNewReleaseNotes().subscribe();
    window.open(this.releaseNotesLink);
  }
}
