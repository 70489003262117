import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SaveDialogData } from '../../classes/save-dialog-data';
import { SaveDialogResponseEnum } from '../../enums/save-dialog-response.enum';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SaveDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveDialogData
  ) {}

  ngOnInit() {}

  save() {
    this.dialogRef.close(SaveDialogResponseEnum.Save);
  }

  exit() {
    this.dialogRef.close(SaveDialogResponseEnum.ExitWithSave);
  }

  cancel() {
    this.dialogRef.close(SaveDialogResponseEnum.Cancel);
  }
}
