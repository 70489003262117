import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { SnackbarStatus } from '../../enums/snackbar-status.enum';
import { AppFeatureService } from '../../services/app-feature/app-feature.service';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { YmStoreState } from '../../store';
import { GlobalStoreSelectors } from '../../store/global-store';

@Component({
  selector: 'app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions = new Subscription();
  private ignoreTabChange = false;
  private sicCanAccess = true;
  private changeRequested;
  private setTabs = new Subject();
  tabList = [];

  @ViewChild('tabGroup', { static: false })
  tabGroup: MatTabGroup;

  constructor(
    private router: Router,
    private featureService: AppFeatureService,
    private ymStore$: Store<YmStoreState.State>,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.subscribeToTabChange();
    this.subscribeToGlobalChange();

    this.router.events.subscribe((result) => {
      if (result instanceof NavigationEnd) {
        if (this.router.isActive(AppRoutes.TEMPLATES_PAGE, false)) {
          this.tabGroup.selectedIndex = 0;
        }
        if (this.router.isActive(AppRoutes.YARD_LOCATIONS_PAGE, false)) {
          this.tabGroup.selectedIndex = this.tabGroup._tabs.length > 1 ? 1 : 0;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.tabGroup._handleClick = this.interceptTabChange.bind(this);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Subscribe to Sic Change Events
   */
  private subscribeToGlobalChange(): void {
    const sic$ = this.ymStore$.select(GlobalStoreSelectors.selectGlobalSic);

    this.subscriptions.add(
      sic$.pipe(filter((sic: string) => !!sic)).subscribe((sic: string) => {
        this.checkTabAccess(sic);
      })
    );
  }

  /**
   * Updates when event fires.
   */
  subscribeToTabChange() {
    this.subscriptions.add(
      this.setTabs.subscribe(() => {
        if (this.sicCanAccess) {
          this.tabList = ['Hostler Plans', 'Yard Locations'];
          this.tabGroup.selectedIndex = this.router.isActive(AppRoutes.YARD_LOCATIONS_PAGE, false) ? 1 : 0;
        } else {
          this.tabList = ['Yard Locations'];
          this.tabGroup.selectedIndex = 0;
        }
      })
    );
  }

  /**
   * Check access.  If successful update tabs others
   * display message and redirect to yard locations
   * @param sic
   */
  checkTabAccess(sic: string) {
    this.featureService
      .canTemplatesAccessBySic(sic)
      .pipe(take(1))
      .subscribe((access) => {
        this.sicCanAccess = access;
        if (!access && this.router.isActive(AppRoutes.TEMPLATES_PAGE, false)) {
          this.snackBarService.openSnackBar(`${sic} does not have Hostler Plans enabled.`, SnackbarStatus.warning);
          this.router.navigate([`/${AppRoutes.YARD_LOCATIONS_PAGE}`]);
        } else if (access && this.router.isActive(AppRoutes.YARD_LOCATIONS_PAGE, false)) {
          this.router.navigate([`/${AppRoutes.TEMPLATES_PAGE}`]);
        }

        this.setTabs.next();
      });
  }

  interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    this.tabGroup._tabs.toArray()[this.tabGroup.selectedIndex].isActive = false;
    this.changeRequested = arguments;

    const tabLabel = tab.textLabel.toUpperCase();
    if (tabLabel === 'HOSTLER PLANS') {
      this.router.navigate([`/${AppRoutes.TEMPLATES_PAGE}/list`]);
    }

    if (tabLabel === 'YARD LOCATIONS') {
      this.router.navigate([`/${AppRoutes.YARD_LOCATIONS_PAGE}`]);
    }
  }
}
