import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParkingLocationTypeCd } from '@xpo-ltl/sdk-common';
import { clone as _clone, filter as _filter, get as _get, map as _map, result as _result } from 'lodash';
import { YardLocation } from '../../shared/models/yard-location';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
})
export class EditLocationComponent implements OnInit {
  yardLocation = undefined as YardLocation;
  originalLocation = undefined as YardLocation;
  title = '';
  commitButtonTitle = '';

  constructor(private dialogRef: MatDialogRef<EditLocationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.yardLocation = _get(data, 'yardLocation', this.yardLocation) as YardLocation;

    if (this.yardLocation) {
      this.title = 'Edit Yard Location';
      this.commitButtonTitle = 'Save';
    } else {
      const location: YardLocation = {
        id: 0,
        name: '',
        description: '',
        type: ParkingLocationTypeCd.EMPTY_TRAILERS,
      };

      this.yardLocation = location;
      this.title = 'Create Yard Location';
      this.commitButtonTitle = 'Create';
    }
    this.originalLocation = _clone(this.yardLocation);
  }

  ngOnInit() {}

  cancelClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    this.dialogRef.close(this.yardLocation);
  }

  canSubmit(): boolean {
    return !this.yardLocation.name || this.yardLocation.name === this.originalLocation.name;
  }
}
