import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatInputModule, MatListModule } from '@angular/material';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import { LoginModule } from '@xpo-ltl/login';
import { XpoCardModule, XpoIconModule, XpoPopoverModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlSicSwitcherComponent } from './sic-switcher.component';

@NgModule({
  declarations: [XpoLtlSicSwitcherComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatInputModule,
    MatListModule,
    MatAutocompleteModule,

    ConfigManagerModule,
    LoginModule,

    XpoPopoverModule,
    XpoIconModule,
    XpoCardModule,
  ],
  exports: [XpoLtlSicSwitcherComponent],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
  ],
})
export class SicSwitcherModule {}
