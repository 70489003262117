import { Injectable } from '@angular/core';
import {
  ListLocationFeatureValuesByFeaturePath,
  ListLocationFeatureValuesByFeatureQuery,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { FeatureSetting, ListLocationFeatureValuesByFeatureResp } from '@xpo-ltl-2.0/sdk-location/lib/api-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { Envelope } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppFeatureService {
  private readonly TemplatesCode = 'YMS_TEMPLATES';

  constructor(
    private loginService: LoginService,
    private config: ConfigManagerService,
    private locationService: LocationApiService
  ) {}

  private getFeatures(featureCode: string): Observable<FeatureSetting[]> {
    const path: ListLocationFeatureValuesByFeaturePath = new ListLocationFeatureValuesByFeaturePath();
    const query: ListLocationFeatureValuesByFeatureQuery = new ListLocationFeatureValuesByFeatureQuery();
    path.featureCd = featureCode;
    return this.locationService.listLocationFeatureValuesByFeature(path, query).pipe(
      map((response: Envelope<ListLocationFeatureValuesByFeatureResp>) => {
        if (response && response.data && response.data.locationFeatures) {
          return response.data.locationFeatures;
        }

        return undefined;
      })
    );
  }

  private getFeature(featureCode: string, sic: string, defaultValue?: any): Observable<string> {
    return this.getFeatures(featureCode).pipe(
      map((settings) => {
        if (settings) {
          const sicFeature = settings.find((feat) => feat.sicCd === sic);

          if (sicFeature) {
            return sicFeature.settingValue;
          }

          const globalFeature = settings.find(
            (feat) => feat.sicCd === '' || feat.sicCd === undefined || feat.sicCd === null
          );
          if (globalFeature) {
            return globalFeature.settingValue;
          }
        }

        return defaultValue;
      })
    );
  }

  canTemplatesAccessBySic(sic: string): Observable<boolean> {
    return this.getFeature(this.TemplatesCode, sic, 'N').pipe(
      map((setting) => {
        return setting === 'Y';
      })
    );
  }
}
