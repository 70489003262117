import { NgModule } from '@angular/core';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { MaterialModule } from '../../material.module';
import { EditLocationComponent } from './edit-location/edit-location.component';

export const dialogs = [
  // YOUR DIALOGS
  EditLocationComponent,
];

@NgModule({
  declarations: [dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MaterialModule,
  ],
  exports: [dialogs],
  entryComponents: [dialogs],
})
export class LocationDialogModule {}
