import { Action } from '@ngrx/store';

export enum ActionTypes {
  setSic = 'globalFilter.setSic',
}

export class SetSicAction implements Action {
  readonly type = ActionTypes.setSic;
  constructor(public payload: { sic: string }) {}
}

export type Actions = SetSicAction;
