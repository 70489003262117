import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { ReleaseNotesUpdateService } from '../../services/release-notes-update/release-notes-update.service';

@Component({
  selector: 'app-updated-release-notes',
  templateUrl: './updated-release-notes.component.html',
  styleUrls: ['./updated-release-notes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdatedReleaseNotesComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<UpdatedReleaseNotesComponent>,
    private configManagerService: ConfigManagerService,
    private releaseNotesUpdateService: ReleaseNotesUpdateService
  ) {
    this.title = configManagerService.getSetting<string[]>(ConfigManagerProperties.appNames)[0];
  }

  title: string;

  ngOnInit() {}

  viewNow(): void {
    this.releaseNotesUpdateService.dismissNewReleaseNotes().subscribe();
    window.open(this.configManagerService.getSetting(ConfigManagerProperties.releaseNotesLink));
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
