import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { get as _get, upperCase as _upperCase } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserPreferencesService } from '../user-preference/user-preference.service';

export interface ReleaseNotes {
  region: string;
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesUpdateService {
  constructor(
    private configManagerService: ConfigManagerService,
    private userPreferencesService: UserPreferencesService
  ) {}

  private readonly ReleaseNotes = 'release-notes';

  private showReleaseNotesSubject = new BehaviorSubject<boolean>(false);
  readonly showReleaseNotes$ = this.showReleaseNotesSubject.asObservable();

  private newReleaseNotesExistSubject = new BehaviorSubject<boolean>(false);
  readonly newReleaseNotesExist$ = this.newReleaseNotesExistSubject.asObservable();

  doNewReleaseNotesExist(): Observable<boolean> {
    return new Observable((observer) => {
      this.userPreferencesService
        .getPreferencesFor<ReleaseNotes[]>(this.ReleaseNotes)
        .pipe(take(1))
        .subscribe(
          (results) => {
            const releaseNote = (results || []).find(
              (rn: ReleaseNotes) => _upperCase(_get(rn, 'region')) === _upperCase(this.region)
            );

            const newReleaseNotesExist = !releaseNote || releaseNote.version !== this.version;

            this.newReleaseNotesExistSubject.next(newReleaseNotesExist);
            observer.next(newReleaseNotesExist);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  dismissNewReleaseNotes(): Observable<boolean> {
    return new Observable((observer) => {
      this.userPreferencesService
        .getPreferencesFor<ReleaseNotes[]>(this.ReleaseNotes)
        .pipe(take(1))
        .subscribe(
          (results) => {
            const releaseNotes: ReleaseNotes[] = results || [];
            const index = releaseNotes.findIndex((rn) => _upperCase(_get(rn, 'region')) === _upperCase(this.region));
            if (index >= 0) {
              releaseNotes[index].version = this.version;
            } else {
              releaseNotes.push({ region: this.region, version: this.version });
            }

            this.userPreferencesService
              .updatePreferencesFor<ReleaseNotes[]>(this.ReleaseNotes, releaseNotes)
              .pipe(take(1))
              .subscribe(
                () => {
                  this.newReleaseNotesExistSubject.next(false);

                  observer.next(true);
                  observer.complete();
                },
                (error) => {
                  observer.error(error);
                  observer.complete();
                }
              );
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  showReleaseNotes(): void {
    this.showReleaseNotesSubject.next(true);
  }

  private get region(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
  }

  private get version(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
