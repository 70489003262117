import { tassign } from 'tassign';
import { Actions, ActionTypes } from './global-store.actions';
import { initialState, State } from './global-store.state';

export function globalReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSic:
      return tassign(state, {
        sic: action.payload.sic,
      });

    default: {
      return state;
    }
  }
}
