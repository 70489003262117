import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { globalReducer } from './global-store.reducers';
import { StateKey } from './global-store.state';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(StateKey, globalReducer)],
})
export class GlobalStoreModule {}
