import { Pipe, PipeTransform } from '@angular/core';
import { ConstantsService } from '../../services/constants/constants.service';
import { EQUIPMENT_NUMBER_PATTERN, LEADING_ZEROS_PATTERN, NUMBER_PATTERN } from '../constants/patterns.const';

@Pipe({ name: 'equipment-pipe' })
export class EquipmentPipe implements PipeTransform {
  constructor(private constantsService: ConstantsService) {}

  transform(value: string): string {
    value = this.deleteLeadingZeros(value);
    if (!value) {
      return '';
    }
    const regex = new RegExp(EQUIPMENT_NUMBER_PATTERN);
    if (value.length < 3 || regex.test(value)) {
      return value.toUpperCase();
    }

    const re = /\-/gi;
    const noHyphenValue = value.replace(re, '').toUpperCase();

    if (noHyphenValue.charAt(0).match(NUMBER_PATTERN)) {
      return noHyphenValue.substring(0, 3) + '-' + noHyphenValue.substring(3);
    } else {
      return value.length > 3 ? noHyphenValue.substring(0, 4) + '-' + noHyphenValue.substring(4) : value.toUpperCase();
    }
  }

  private deleteLeadingZeros(value) {
    if (!value) {
      return '';
    }
    const leadingZerosRegex = new RegExp(LEADING_ZEROS_PATTERN, 'g');
    return value.replace(leadingZerosRegex, '');
  }
}
