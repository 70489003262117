import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SicSwitchService {
  private _sicSwitchEvent = new Subject<string>();

  constructor() {}

  getSicChangeEvent(): Observable<string> {
    return this._sicSwitchEvent.asObservable();
  }

  changeSic(sic: string) {
    this._sicSwitchEvent.next(sic);
  }
}
