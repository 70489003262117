import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InputDialogData } from '../../classes/input-dialog-data';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent {
  input: string;
  maxInputLength = -1;
  constructor(
    private dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public config: InputDialogData
  ) {
    this.input = config.text;
    this.maxInputLength = config.maxInputLength;
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      if (this.canSubmit(this.input, this.config.text)) {
        this.saveClicked();
      }
    }
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  saveClicked() {
    this.dialogRef.close(this.input);
  }

  private canSubmit(text: string, original: string): boolean {
    if (this.config.canSubmit) {
      return this.config.canSubmit(text, original);
    }
    return true;
  }

  disableSave(text: string, original: string): boolean {
    return !this.canSubmit(text, original);
  }
}
