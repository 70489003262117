import { Injectable } from '@angular/core';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-auth';
import { AppMetadataApiService, ListApplicationsResp } from '@xpo-ltl/sdk-appmetadata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class XpoAppSwitcherService {
  constructor(private appMetadataApi: AppMetadataApiService) {}

  getAppList(): Observable<XpoAppSwitcherApplication[]> {
    return this.appMetadataApi.listApplications().pipe(
      map((envelope: any) => {
        if (envelope && envelope.data) {
          return envelope.data.applications
            .filter((item) => item.appName.toLowerCase() !== 'yard management')
            .map((app) => {
              const xpoApp: XpoAppSwitcherApplication = {} as XpoAppSwitcherApplication;
              xpoApp.name = app.appName;
              xpoApp.url = app.url;
              return xpoApp;
            });
        }

        return [];
      })
    );
  }
}
