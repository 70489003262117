import { NgModule } from '@angular/core';
import { XpoAutofocusModule, XpoButtonModule, XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { MaterialModule } from '../material.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ErrorComponent } from './error/error.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { UndoRunDialogComponent } from './undo-run-dialog/undo-run.dialog.service';

const dialogs = [
  // YOUR DIALOGS
  ErrorComponent,
  InputDialogComponent,
  NotAuthorizedComponent,
  ConfirmDialogComponent,
  SaveDialogComponent,
];

@NgModule({
  declarations: [...dialogs, InputDialogComponent],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MaterialModule,
    XpoAutofocusModule,
    XpoButtonModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
