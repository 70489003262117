import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { GlobalStoreModule } from './global-store';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forRoot([]), GlobalStoreModule],
})
export class YmStoreModule {}
