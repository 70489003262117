import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger, MatInput } from '@angular/material';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { first as _first, get as _get, size as _size } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ServiceCentersService } from '../../services/service-centers/service-centers.service';

@Component({
  selector: 'sic-switcher',
  templateUrl: './sic-switcher.component.html',
  styleUrls: ['./sic-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class XpoLtlSicSwitcherComponent implements OnInit {
  @ViewChild(MatInput, { static: false }) sicInput: MatInput;
  @ViewChild(MatAutocomplete, { static: false }) sicAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: false }) sicAutocompleteTrigger: MatAutocompleteTrigger;

  get sicCd(): string {
    return _get(this.selectedSic, 'sicCd', '');
  }
  @Input()
  set sicCd(value: string) {
    this.selectSicWithCd(value);
  }

  @Input() panelWidth: string = 'auto';

  @Output() sicChange = new EventEmitter<ServiceCenter>();

  private selectedSic: ServiceCenter;
  private previousSic: ServiceCenter;
  private previousFilterTerm = '';

  filteredSicList$: Observable<ServiceCenter[]>;

  constructor(private serviceCentersService: ServiceCentersService) {}

  ngOnInit() {
    this.filteredSicList$ = this.serviceCentersService.serviceCenters$;
  }

  // #region PUBLIC API

  /**
   * Set the focus to the input control
   */
  focus() {
    this.sicInput.focus();
  }

  // #endregion PUBLIC AIP

  // #region PRIVATE API

  handleFilterFocus() {
    this.previousSic = this.selectedSic;
  }

  handleFilterBlur() {
    if (!this.sicAutocomplete.isOpen) {
      this.selectSicFromFilter();
    }
  }

  handlePanelClosed() {
    if (this.sicInput.value !== _get(this.selectedSic, 'sicCd')) {
      this.selectSic(this.selectedSic);
    }
  }

  /**
   * Update the list of ServiceCenters to contain the list that matches the serch term.
   * @param filterTerm term to filter ServiceCenters by
   */
  updateFilteredSics(filterTerm: string) {
    if (filterTerm !== this.previousFilterTerm) {
      this.filteredSicList$ = this.serviceCentersService.serviceCenters$.pipe(
        map((sics: ServiceCenter[]) => this.serviceCentersService.filterSicsBy(sics, filterTerm))
      );
      this.previousFilterTerm = filterTerm;
    }
  }

  displayFn(sic: ServiceCenter) {
    if (sic) {
      return `${sic.sicCd}`;
    } else {
      return undefined;
    }
  }

  /**
   * Select the passed ServiceCenter and update UI to reflect it
   * @param newSic ServiceCenter to select
   */
  selectSic(newSic: ServiceCenter) {
    if (this.selectedSic !== newSic) {
      // only broadcast event if the new sic is different from previous sic
      this.sicChange.emit(newSic);
      this.selectedSic = newSic;
      this.previousSic = newSic;
    }

    // reset the filterTerm to be the selected SicCd
    const sicCd = this.selectedSic ? `${this.selectedSic.sicCd} - ${this.selectedSic.reference.sicName}` : '';
    if (this.sicInput) {
      this.sicInput.value = sicCd;
    }
    this.updateFilteredSics(sicCd);
  }

  /**
   * Select a ServiceCenter that has the passed sicCd
   *
   * @param sicCd sicCd for the ServiceCenter to select
   */
  private selectSicWithCd(sicCd: string) {
    this.serviceCentersService
      .getSicByCd$(sicCd)
      .pipe(take(1))
      .subscribe((sic: ServiceCenter) => {
        this.selectSic(sic);
      });
  }

  /**
   * If only one sic in the fitler list, select it. Else, clear the selection
   */
  private selectSicFromFilter() {
    this.filteredSicList$.pipe(take(1)).subscribe((filteredSics: ServiceCenter[]) => {
      if (_size(filteredSics) === 1) {
        // auto-select the only option
        this.selectSic(_first(filteredSics));
      } else {
        // there are more then one option, so can't auto-select from filter list
        this.selectSic(this.previousSic);
      }
    });
  }

  // #endregion
}
