import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { ConfirmDialogData } from '../classes/confirm-dialog-data';
import { ErrorData } from '../classes/error-data';
import { InputDialogData } from '../classes/input-dialog-data';
import { SaveDialogData } from '../classes/save-dialog-data';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { InputDialogComponent } from '../dialogs/input-dialog/input-dialog.component';
import { SaveDialogComponent } from '../dialogs/save-dialog/save-dialog.component';
import { SaveDialogResponseEnum } from '../enums/save-dialog-response.enum';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  input(
    title: string,
    text: string = '',
    hint: string = '',
    saveText: string = 'OK',
    cancelText: string = 'CANCEL',
    canSubmit: (text: string, original) => boolean,
    maxInputLength: number = -1
  ): Observable<string> {
    const subject = new Subject<string>();
    const config = new InputDialogData(title, text, hint, saveText, cancelText, canSubmit, maxInputLength);
    const dialogRef = this.dialog.open(InputDialogComponent, { data: config });
    dialogRef.afterClosed().subscribe((input: string) => {
      subject.next(input);
      subject.complete();
    });

    return subject;
  }

  showError(title: string, errorMessage: string): Observable<void> {
    const subject = new Subject<void>();
    const dialogRef = this.dialog.open(ErrorComponent, {
      data: new ErrorData(title, errorMessage),
    });
    dialogRef.afterClosed().subscribe(() => {
      subject.next();
      subject.complete();
    });
    return subject;
  }

  confirm(title: string, message: string, confirmText: string, cancelText: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: new ConfirmDialogData(title, message, confirmText, cancelText),
    });
    dialogRef.afterClosed().subscribe((result) => {
      subject.next(result);
    });
    return subject;
  }

  save(
    title: string,
    message: string,
    confirmText: string,
    confirmNoActionText: string,
    cancelText: string
  ): Observable<SaveDialogResponseEnum> {
    const subject = new Subject<SaveDialogResponseEnum>();
    const dialogRef = this.dialog.open(SaveDialogComponent, {
      data: new SaveDialogData(title, message, confirmText, confirmNoActionText, cancelText),
    });
    dialogRef.afterClosed().subscribe((result) => {
      subject.next(result);
      subject.complete();
    });
    return subject;
  }
}
