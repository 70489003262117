import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from './enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.YARD_LOCATIONS_PAGE,
    loadChildren: () => import('./yard-location/yard-location.module').then((mod) => mod.YardLocationModule),
  },
  {
    path: AppRoutes.TEMPLATES_PAGE,
    loadChildren: () => import('./templates/templates.module').then((mod) => mod.TemplatesModule),
  },
  {
    path: '**',
    redirectTo: `/${AppRoutes.TEMPLATES_PAGE}/list`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
