import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { XpoLtlReleaseNotesService, XpoLtlSicSwitcherComponent } from '@xpo/ngx-ltl';
import { defaultTo } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import { UpdatedReleaseNotesComponent } from './components/updated-release-notes/updated-release-notes.component';
import { AccountUrls } from './enums/account-urls.enum';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { ReleaseNotesUpdateService } from './services/release-notes-update/release-notes-update.service';
import { ServiceCentersService } from './services/service-centers/service-centers.service';
import { SicSwitchService } from './services/sic-switch/sic-switch.service';
import { XpoAppSwitcherService } from './services/xpo-app-switcher/xpo-app-switcher.service';
import { GlobalStoreActions, GlobalStoreSelectors, YmStoreState } from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { class: 'ym-Root' },
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit, OnDestroy {
  constructor(
    private configManagerService: ConfigManagerService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private serviceCentersService: ServiceCentersService,
    private appSwitcherService: XpoAppSwitcherService,
    private ymStore$: Store<YmStoreState.State>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private releaseNotesUpdateService: ReleaseNotesUpdateService,
    private sicSwitchService: SicSwitchService,
    private authService: XpoLtlAuthenticationService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      iconRegistry.addSvgIcon('pencil', sanitizer.bypassSecurityTrustResourceUrl('../assets/images/pencil.svg'));
      iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('../assets/images/delete.svg'));

      /** Shell setup */
      this.title = configManagerService.getSetting<string[]>(ConfigManagerProperties.appNames)[0];
      this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

      /** Initialize SIC switcher by getting logged in user's sic */
      this.handleLoggedInUser();

      this.subscriptions.add(
        this.loginService.userLoggedIn$.subscribe(() => {
          this.handleLoggedInUser();
        })
      );
    });
  }
  @ViewChild('sicSwitcher', { static: true }) sicSwitcher: XpoLtlSicSwitcherComponent;

  title: string;
  build: string;
  currentSic = '';
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  private subscriptions = new Subscription();

  PROFILE_URLS = AccountUrls;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.sicSwitchService.getSicChangeEvent().subscribe((sic) => {
        this.sicSwitcher.sicCd = sic;
      })
    );

    this.subscriptions.add(
      this.ymStore$.select(GlobalStoreSelectors.selectGlobalSic).subscribe((sicCd: string) => {
        if (sicCd) {
          this.serviceCentersService
            .getSicByCd$(sicCd)
            .pipe(take(1))
            .subscribe((sic: ServiceCenter) => {
              if (sic) {
                this.currentSic = sic.sicCd;
              } else {
                this.setDefaultSic();
              }
            });
        } else {
          this.setDefaultSic();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setDefaultSic() {
    this.currentSic = '';
    this.sicSwitcher.focus();
  }

  handleSicChanged(sic: string): void {
    if (sic && sic !== this.currentSic) {
      this.ymStore$.dispatch(new GlobalStoreActions.SetSicAction({ sic: sic }));
    }
  }

  private handleLoggedInUser(): void {
    this.subscriptions.add(
      this.loginService
        .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              delay(1000),
              take(5)
            )
          )
        )
        .subscribe(
          (user: User) => {
            if (user) {
              this.checkForUpdatedReleaseNotes();
              this.populateAppSwitcher();
              const permittedSicPrefixes = ['U', 'X', 'N', 'L'];
              const userSicCd = defaultTo(user.sicCode, '');

              if (permittedSicPrefixes.indexOf(userSicCd.toUpperCase()[0]) > -1) {
                this.ymStore$.dispatch(new GlobalStoreActions.SetSicAction({ sic: userSicCd }));
              } else {
                if (this.configManagerService.getSetting(ConfigManagerProperties.buildVersion) === 'local-version') {
                  // default to UPO for local bulds.
                  this.ymStore$.dispatch(new GlobalStoreActions.SetSicAction({ sic: 'UPO' }));
                } else if (userSicCd.toUpperCase().startsWith('I')) {
                  this.ymStore$.dispatch(new GlobalStoreActions.SetSicAction({ sic: 'UPO' }));
                }
              }
            }
          },
          (error) => {
            console.log('ERROR', error);
          }
        )
    );
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private checkForUpdatedReleaseNotes(): void {
    this.releaseNotesUpdateService.showReleaseNotes();

    this.releaseNotesUpdateService
      .doNewReleaseNotesExist()
      .pipe(take(1))
      .subscribe((updatesExist) => {
        if (updatesExist) {
          this.releaseNotesUpdateService.showReleaseNotes$.subscribe((showReleaseNotes) => {
            if (showReleaseNotes) {
              this.showReleaseNotes();
            }
          });
        }
      });
  }

  private showReleaseNotes(): void {
    this.dialog.open(UpdatedReleaseNotesComponent, {
      disableClose: true,
      hasBackdrop: false,
    });
  }
}
