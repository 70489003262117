export class InputDialogData {
  constructor(
    public title: string = '',
    public text: string = '',
    public hint: string = '',
    public saveText = 'OK',
    public cancelText = 'CANCEL',
    public canSubmit: (text: string, original: string) => boolean,
    public maxInputLength: number = -1
  ) {}
}
