import { Injectable } from '@angular/core';
import { MatSnackBarVerticalPosition } from '@angular/material';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { SnackbarStatus } from '../../enums/snackbar-status.enum';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(private snackbar: XpoSnackBar, private configManagerService: ConfigManagerService) {}
  /**
   * Opens a snack bar (toast) message.
   * @param message
   * @param status
   */
  openSnackBar(message: string, status: SnackbarStatus, position?: MatSnackBarVerticalPosition): void {
    let duration = 5000; // Default duration
    switch (status) {
      case SnackbarStatus.success:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.successToastDuration);
        break;
      case SnackbarStatus.error:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration);
        break;
    }
    this.snackbar.open({
      message: message,
      detailedMessage: ``,
      status: status,
      action: undefined,
      matConfig: {
        duration,
        verticalPosition: position ? position : 'bottom',
        panelClass: `ym-Snackbar-${status}`,
      },
    });
  }
}
