import { Injectable } from '@angular/core';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { get as _get, isEmpty as _isEmpty, isString as _isString } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMapTo, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(private userPreferenceApiService: UserPreferenceApiService) {}

  /**
   * Update the preferences for the named component
   */
  updatePreferencesFor<PREF_TYPE>(componentName: string, preferences: PREF_TYPE): Observable<PREF_TYPE> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = componentName;
    upsertPreferencesRequest.preferences = JSON.stringify(preferences);
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceApiService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery)
      .pipe(switchMapTo(of(preferences)));
  }

  /**
   * Get the user preferences stored for the named component
   * @param componentName name of the component to fetch stored preferences
   */
  getPreferencesFor<PREF_TYPE>(componentName: string): Observable<PREF_TYPE> {
    const getPreferencesQuery = new GetUserPreferenceQuery();
    getPreferencesQuery.uiComponentName = componentName;
    return this.userPreferenceApiService.getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false }).pipe(
      map((response) => response.data),
      map((response) => {
        let rawPreferences = [];

        if (_get(response, 'preferences', false)) {
          if (!_isString(response.preferences)) {
            rawPreferences = response.preferences;
          } else if (!_isEmpty(response.preferences)) {
            rawPreferences = response.preferences as any;
          }
        }

        try {
          const preference: PREF_TYPE = (_isString(rawPreferences)
            ? JSON.parse(rawPreferences)
            : rawPreferences) as PREF_TYPE;
          return preference;
        } catch (err) {
          return undefined;
        }
      }),
      catchError((error) => of(undefined))
    );
  }

  /**
   * Clear preferences for the named component by writting an empty object
   * @param componentName name of component to clear preferences for
   */
  clearPreferencesFor(componentName: string): Observable<void> {
    return this.updatePreferencesFor(componentName, []).pipe(switchMapTo(of(undefined)));
  }
}
